<template>
  <div class="moonpay-currency-details-container wrapper">
    <CRow class>
      <CCol lg="9" md="12">
        <CCard>
          <CCardHeader>
            <router-link :to="moonpayCurrencyListUrl">
              <CIcon name="cil-arrow-left" class="mr-1" />
              {{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_BACK_LINK_BUTTON') }}
            </router-link>
          </CCardHeader>

          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody>
            <ValidationObserver ref="form" v-if="!isLoading" v-slot="{ handleSubmit, dirty }">
              <CForm @submit.prevent="handleSubmit(onSubmit)">
                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_NAME_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_NAME_FIELD_NAME" rules="required|max:128">
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.name" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_ICON_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_ICON_FIELD_NAME" rules="required|max:256">
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.icon" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_SYMBOL_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_SYMBOL_FIELD_NAME" rules="required|max:128|alpha_num">
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.symbol" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_PLATFORM_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_PLATFORM_FIELD_NAME" rules="max:128|alpha_num">
                      <div slot-scope="{ errors }">
                        <input
                          @change="validContractAddress(currencyDetails.platform, currencyDetails.contract_address)"
                          type="text"
                          v-model="currencyDetails.platform"
                          class="form-control"
                        />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_STATUS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_STATUS_FIELD_NAME" rules="required">
                      <div slot-scope="{ errors }">
                        <select v-model="currencyDetails.status" class="form-control">
                          <option value>{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_CHOSE_A_STATUS_LABEL') }}</option>
                          <option v-for="item in statuses" :key="item.value" :value="item.value">{{ item.label }}</option>
                        </select>
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_MS_STATUS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_MS_STATUS_FIELD_NAME" rules="required">
                      <div slot-scope="{ errors }">
                        <select v-model="currencyDetails.ms_status" class="form-control">
                          <option value>{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_CHOSE_A_MS_STATUS_LABEL') }}</option>
                          <option v-for="item in statuses" :key="item.value" :value="item.value">{{ item.label }}</option>
                        </select>
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_DECIMALS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_DECIMALS_FIELD_NAME" rules="min_value:0">
                      <div slot-scope="{ errors }">
                        <currency-input class="input-field form-control" v-model="currencyDetails.decimals" :precision="0" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_ORDER_INDEX_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_ORDER_INDEX_FIELD_NAME" rules>
                      <div slot-scope="{ errors }">
                        <currency-input class="input-field form-control" v-model="currencyDetails.order_index" :precision="0" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <!-- <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_FROM_FLAG_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_FROM_FLAG_FIELD_NAME">
                      <div>
                        <div class="btn-switch-wrapper">
                          <label class="btn-switch">
                            <input type="checkbox" class="checkbox" v-model="currencyDetails.from_flg" />
                            <span class="check-silder"></span>
                          </label>
                        </div>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_TO_FLAG_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_TO_FLAG_FIELD_NAME">
                      <div>
                        <div class="btn-switch-wrapper">
                          <label class="btn-switch">
                            <input type="checkbox" class="checkbox" v-model="currencyDetails.to_flg" />
                            <span class="check-silder"></span>
                          </label>
                        </div>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow> -->

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_CONTRACT_ADDRESS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_CONTRACT_ADDRESS_FIELD_NAME">
                      <div>
                        <div class="btn-switch-wrapper">
                          <label class="btn-switch">
                            <input
                              @change="validContractAddress(currencyDetails.platform, currencyDetails.contract_address)"
                              type="checkbox"
                              class="checkbox"
                              v-model="currencyDetails.contract_flg"
                            />
                            <span class="check-silder"></span>
                          </label>
                        </div>
                      </div>
                    </ValidationProvider>
                  </CCol>
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_CONTRACT_ADDRESS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_CONTRACT_ADDRESS_FIELD_NAME" rules="max:256">
                      <div slot-scope="{ errors }">
                        <input
                          @change="validContractAddress(currencyDetails.platform, currencyDetails.contract_address)"
                          type="text"
                          v-model="currencyDetails.contract_address"
                          class="form-control"
                        />
                        <span class="error-msg-wrap">
                          <span v-if="!isValidContractAddress" class="error-msg">{{
                            $t('EXCHANGE_CURRENCY_DETAILS_PAGE_CONTRACT_ADDRESS_INVALID')
                          }}</span>
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_TYPE_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_TYPE_FIELD_NAME" rules="required">
                      <div slot-scope="{ errors }">
                        <select v-model="currencyDetails.type" class="form-control">
                          <option value>{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_CHOSE_A_TYPE_LABEL') }}</option>
                          <option value="coin">coin</option>
                          <option value="token">token</option>
                        </select>
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_ADDRESS_REGEX_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_ADDRESS_REGEX_FIELD_NAME" rules>
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.address_regex" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>
                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_IS_POPULAR_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_IS_POPULAR_FIELD_NAME" rules>
                      <div>
                        <div class="btn-switch-wrapper">
                          <label class="btn-switch">
                            <input type="checkbox" class="checkbox" v-model="currencyDetails.is_popular" />
                            <span class="check-silder"></span>
                          </label>
                        </div>
                      </div>
                    </ValidationProvider>
                  </CCol>
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_TESTNET_ADDRESS_REGEX_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_TESTNET_ADDRESS_REGEX_FIELD_NAME" rules>
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.testnet_address_regex" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>
                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_MIN_AMOUNT_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_MIN_AMOUNT_FIELD_NAME" rules="min_value:0">
                      <div slot-scope="{ errors }">
                        <input type="number" v-model="currencyDetails.min_amount" class="form-control" min="0" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_MAX_AMOUNT_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_MAX_AMOUNT_FIELD_NAME" rules="min_value:0">
                      <div slot-scope="{ errors }">
                        <input type="number" v-model="currencyDetails.max_amount" class="form-control" min="0" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>
                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label"> {{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_SUPPORT_ADDRESS_TAG_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_SUPPORT_ADDRESS_TAG_FIELD_NAME" rules>
                      <div>
                        <div class="btn-switch-wrapper">
                          <label class="btn-switch">
                            <input type="checkbox" class="checkbox" v-model="currencyDetails.supports_address_tag" />
                            <span class="check-silder"></span>
                          </label>
                        </div>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>
                <CRow class="form-group" v-if="currencyDetails.supports_address_tag">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_ADDRESS_TAG_REGEX_LABEL') }}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_ADDRESS_TAG_REGEX_FIELD_NAME" rules="max:256">
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.address_tag_regex" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>
                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_SUPPORT_TEST_MODE_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_SUPPORT_TEST_MODE_FIELD_NAME" rules>
                      <div>
                        <div class="btn-switch-wrapper">
                          <label class="btn-switch">
                            <input type="checkbox" class="checkbox" v-model="currencyDetails.supports_test_mode" />
                            <span class="check-silder"></span>
                          </label>
                        </div>
                      </div>
                    </ValidationProvider>
                  </CCol>
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_IS_SELL_SUPPORTED_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_IS_SELL_SUPPORTED_FIELD_NAME" rules>
                      <div>
                        <div class="btn-switch-wrapper">
                          <label class="btn-switch">
                            <input type="checkbox" class="checkbox" v-model="currencyDetails.is_sell_supported" />
                            <span class="check-silder"></span>
                          </label>
                        </div>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>
                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label"> {{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_IS_SUPPORTED_IN_US_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_IS_SUPPORTED_IN_US_FIELD_NAME" rules>
                      <div>
                        <div class="btn-switch-wrapper">
                          <label class="btn-switch">
                            <input type="checkbox" class="checkbox" v-model="currencyDetails.is_supported_in_us" />
                            <span class="check-silder"></span>
                          </label>
                        </div>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>
                <div v-if="currencyDetails.is_supported_in_us">
                  <CRow class="form-group" v-for="(state, index) in currencyDetails.not_allowed_us_states" :key="index">
                    <CCol lg="2" md="2">
                      <label class="col-form-label" v-if="index === 0">{{
                        $t('MOONPAY_CURRENCY_DETAILS_PAGE_NOT_ALLOW_US_STATES_LABEL')
                      }}</label>
                    </CCol>
                    <CCol :lg="{ size: '4' }" :md="{ size: '4' }">
                      <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_NOT_ALLOW_US_STATES_FIELD_NAME" rules="required|max:5">
                        <div slot-scope="{ errors }">
                          <input class="form-control" type="text" v-model="currencyDetails.not_allowed_us_states[index]" />
                          <span class="error-msg-wrap">
                            <span class="error-msg">{{ errors[0] }}</span>
                          </span>
                        </div>
                      </ValidationProvider>
                    </CCol>
                    <CCol lg="2" md="2">
                      <button class="btn btn-primary" type="button" @click="deleteState(index)">Delete</button>
                    </CCol>
                  </CRow>
                  <CRow class="form-group">
                    <CCol lg="2" md="2">
                      <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_ADD_STATE_LABEL') }}</label>
                    </CCol>
                    <CCol :lg="{ size: '4' }" :md="{ size: '4' }">
                      <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_NEW_STATE_FIELD_NAME" rules="max:5">
                        <div slot-scope="{ errors }">
                          <input class="form-control" type="text" v-model="newState" />
                          <span class="error-msg-wrap">
                            <span class="error-msg">{{ errors[0] }}</span>
                          </span>
                        </div>
                      </ValidationProvider>
                    </CCol>
                    <CCol :lg="{ size: '2' }" :md="{ size: '2' }">
                      <button class="btn btn-primary" type="button" @click="addState">Add</button>
                    </CCol>
                  </CRow>
                </div>
                <!-- <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_DESCRIPTION_LABEL') }}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <ValidationProvider name="MOONPAY_CURRENCY_DETAILS_PAGE_DESCRIPTION_FIELD_NAME" rules="max:1000">
                      <div slot-scope="{ errors }">
                        <textarea v-model="currencyDetails.description" rows="5" class="form-control"></textarea>
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow> -->
                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_MOONPAY_SYMBOL_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider :name="$t('MOONPAY_CURRENCY_DETAILS_PAGE_MOONPAY_SYMBOL_FIELD_NAME')" rules="required|max:128|alpha_num">
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.moonpay_symbol" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_ANDROID_STATUS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider :name="$t('MOONPAY_CURRENCY_DETAILS_PAGE_ANDROID_STATUS_FIELD_NAME')" rules="required">
                      <div slot-scope="{ errors }">
                        <select v-model="currencyDetails.mobile_android_status" class="form-control">
                          <option value>{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_CHOSE_AN_ANDROID_STATUS_LABEL') }}</option>
                          <option v-for="item in statuses" :key="item.value" :value="item.label.toUpperCase()">{{ item.label }}</option>
                        </select>
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>
                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_IOS_STATUS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider :name="$t('MOONPAY_CURRENCY_DETAILS_PAGE_IOS_STATUS_FIELD_NAME')" rules="required">
                      <div slot-scope="{ errors }">
                        <select v-model="currencyDetails.mobile_ios_status" class="form-control">
                          <option value>{{ $t('MOONPAY_CURRENCY_DETAILS_PAGE_CHOSE_AN_IOS_STATUS_LABEL') }}</option>
                          <option v-for="item in statuses" :key="item.value" :value="item.label.toUpperCase()">{{ item.label }}</option>
                        </select>
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>
                <CRow class="form-actions mt-3">
                  <CCol lg="12" md="12">
                    <CButton @click="cancel(dirty)" v-if="!isEdit || (isEdit && isAllowedUpdating)" class="btn btn-secondary btn-cancel">{{
                      $t('MOONPAY_CURRENCY_DETAILS_PAGE_CANCEL_BUTTON')
                    }}</CButton>

                    <CButton @click="onSubmit()" v-if="!isEdit" color="primary" class="btn btn-update btn-create">{{
                      $t('MOONPAY_CURRENCY_DETAILS_PAGE_CREATE_BUTTON')
                    }}</CButton>

                    <CButton @click="onSubmit()" v-if="isEdit && isAllowedUpdating" color="primary" class="btn btn-update">{{
                      $t('MOONPAY_CURRENCY_DETAILS_PAGE_UPDATE_BUTTON')
                    }}</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>

        <StakingModal :linkTo="moonpayCurrencyListUrl" :warningLeavePage="warningLeavePage" @close-modal="closeModal" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import StakingModal from '@/components/StakingModal';
import verifyAddress from '@/utils/verify-address';

export default {
  name: 'EditMoonpayCurrency',
  components: {
    VclList,
    StakingModal,
  },
  data() {
    return {
      currencyId: null,
      currencyDetails: null,
      statuses: [],
      isEdit: false,
      isLoading: true,
      isAllowedUpdating: false,
      warningLeavePage: false,
      moonpayCurrencyListUrl: '/admin/exchange/moonpay-currencies',
      isValidContractAddress: true,
      newState: '',
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    const currencyId = this.$route.params.currencyId;
    this.checkPermissions();

    if (currencyId) {
      this.isEdit = true;
      this.currencyId = currencyId;
      await Promise.all([this.getMoonpayCurrencyDetails(currencyId), this.getMoonpayCurrencyStatuses()]);
      this.isLoading = false;
    } else {
      this.isEdit = false;
      await Promise.all([this.getMoonpayCurrencyStatuses()]);
      this.currencyDetails = {
        status: null,
        ms_status: null,
        type: '',
        icon: '',
        not_allowed_us_states: [],
      };
      this.isLoading = false;
    }
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'UPDATE_EXCHANGE_CURRENCY') {
            this.isAllowedUpdating = true;
          }
        });
      }
    },
    async getMoonpayCurrencyStatuses() {
      try {
        const result = await this.$http.get(endpoints.getMoonpayCurrencyStatuses);
        this.statuses = stringHelper.translateTextForDropdownList(result.data, 'MOONPAY_CURRENCY_STATUS');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MOONPAY_CURRENCY_DETAILS_PAGE_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getMoonpayCurrencyDetails(currencyId) {
      try {
        const res = await this.$http.get(endpoints.getMoonpayCurrencyDetails(currencyId));
        this.currencyDetails = res.data;
        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MOONPAY_CURRENCY_DETAILS_PAGE_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    backToMoonpayCurrencyList() {
      this.$router.push(this.moonpayCurrencyListUrl);
    },
    closeModal(val) {
      this.warningLeavePage = val;
    },
    cancel(dirty) {
      if (dirty) {
        this.warningLeavePage = true;
      } else {
        this.$router.push(this.moonpayCurrencyListUrl);
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      if (this.currencyDetails.contract_flg && !this.isValidContractAddress) {
        return;
      }

      if (this.isEdit) {
        return this.updateMoonpayCurrency();
      }

      this.createMoonpayCurrency();
    },
    async updateMoonpayCurrency() {
      if (this.currencyDetails.isProcessing) {
        return;
      }

      this.currencyDetails.isProcessing = true;

      try {
        const data = {
          name: this.currencyDetails.name,
          symbol: this.currencyDetails.symbol,
          platform: this.currencyDetails.platform,
          icon: this.currencyDetails.icon || '',
          decimals: this.currencyDetails.decimals || 0,
          order_index: this.currencyDetails.order_index || null,
          // description: this.currencyDetails.description || null,
          contract_address: this.currencyDetails.contract_address || null,
          contract_flg: this.currencyDetails.contract_flg || false,
          status: parseInt(this.currencyDetails.status),
          ms_status: parseInt(this.currencyDetails.ms_status),
          type: this.currencyDetails.type || 'coin',
          address_regex: this.currencyDetails.address_regex || null,
          testnet_address_regex: this.currencyDetails.testnet_address_regex || null,
          min_amount: this.currencyDetails.min_amount || null,
          max_amount: this.currencyDetails.max_amount || null,
          is_popular: this.currencyDetails.is_popular || false,
          supports_address_tag: this.currencyDetails.supports_address_tag || false,
          address_tag_regex: this.currencyDetails.address_tag_regex || null,
          supports_test_mode: this.currencyDetails.supports_test_mode || false,
          is_supported_in_us: this.currencyDetails.is_supported_in_us || false,
          is_sell_supported: this.currencyDetails.is_sell_supported || false,
          not_allowed_us_states: this.currencyDetails.not_allowed_us_states || [],
          moonpay_symbol: this.currencyDetails.moonpay_symbol,
          mobile_ios_status: this.currencyDetails.mobile_ios_status === 'ENABLED' ? 1 : -1,
          mobile_android_status: this.currencyDetails.mobile_android_status === 'ENABLED' ? 1 : -1,
        };

        const result = await this.$http.put(endpoints.updateMoonpayCurrency(this.currencyId), data);

        this.currencyDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MOONPAY_CURRENCY_DETAILS_PAGE_NOTIFICATION_SUCCESS_TITLE'),
          text: this.$t('MOONPAY_CURRENCY_DETAILS_PAGE_UPDATE_MOONPAY_CURRENCY_SUCCESS'),
        });
        this.backToMoonpayCurrencyList();
      } catch (err) {
        this.currencyDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MOONPAY_CURRENCY_DETAILS_PAGE_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async createMoonpayCurrency() {
      if (this.currencyDetails.isProcessing) {
        return;
      }

      this.currencyDetails.isProcessing = true;
      try {
        const data = {
          name: this.currencyDetails.name,
          symbol: this.currencyDetails.symbol,
          platform: this.currencyDetails.platform,
          icon: this.currencyDetails.icon || '',
          decimals: this.currencyDetails.decimals || 0,
          order_index: this.currencyDetails.order_index || null,
          // description: this.currencyDetails.description || null,
          contract_address: this.currencyDetails.contract_address || null,
          contract_flg: this.currencyDetails.contract_flg || false,
          status: parseInt(this.currencyDetails.status),
          ms_status: parseInt(this.currencyDetails.ms_status),
          type: this.currencyDetails.type || 'coin',
          address_regex: this.currencyDetails.address_regex || null,
          testnet_address_regex: this.currencyDetails.testnet_address_regex || null,
          min_amount: this.currencyDetails.min_amount || null,
          max_amount: this.currencyDetails.max_amount || null,
          is_popular: this.currencyDetails.is_popular || false,
          supports_address_tag: this.currencyDetails.supports_address_tag || false,
          address_tag_regex: this.currencyDetails.address_tag_regex || null,
          supports_test_mode: this.currencyDetails.supports_test_mode || false,
          is_supported_in_us: this.currencyDetails.is_supported_in_us || false,
          is_sell_supported: this.currencyDetails.is_sell_supported || false,
          not_allowed_us_states: this.currencyDetails.not_allowed_us_states || [],
          moonpay_symbol: this.currencyDetails.moonpay_symbol,
          mobile_ios_status: this.currencyDetails.mobile_ios_status === 'ENABLED' ? 1 : -1,
          mobile_android_status: this.currencyDetails.mobile_android_status === 'ENABLED' ? 1 : -1, 
        };
        const result = await this.$http.post(endpoints.createMoonpayCurrency, data);

        this.currencyDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MOONPAY_CURRENCY_DETAILS_PAGE_NOTIFICATION_SUCCESS_TITLE'),
          text: this.$t('MOONPAY_CURRENCY_DETAILS_PAGE_CREATE_MOONPAY_CURRENCY_SUCCESS'),
        });

        this.backToMoonpayCurrencyList();
      } catch (err) {
        this.currencyDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MOONPAY_CURRENCY_DETAILS_PAGE_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    validContractAddress(platform, contractAddress) {
      try {
        if (platform !== ('' || undefined)) {
          this.isValidContractAddress = verifyAddress.multiAddress(platform.toUpperCase(), contractAddress);
        } else {
          this.isValidContractAddress = true;
        }
      } catch (error) {
        console.log(' Missing validator for currency');
      }
    },
    addState() {
      this.currencyDetails.not_allowed_us_states.push(this.newState);
      this.newState = '';
    },
    deleteState(index) {
      this.currencyDetails.not_allowed_us_states.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.moonpay-currency-details-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #695fe5;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
      }
    }

    .form-control-plaintext {
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }

    .form-group {
      margin-bottom: 0.5rem;
      .col-form-label {
        width: 100%;
        text-align: right;
      }

      .btn-switch-wrapper {
        margin-top: 10px;
      }
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 32px;
        width: 200px;
        height: 32px;
        padding: 0 30px;
        color: #fff;
        margin: 0 0 15px 15px;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
        height: 36px;

        &.btn-primary {
          background: #3b4b7e;
        }

        &.btn-update {
          color: #fff;
          font-weight: 500;
          letter-spacing: 0.5px;
          text-align: center;
          background-color: #3b4b7e;
          border: 1px solid #3b4b7e;
          border-radius: 4px;
        }

        &.btn-cancel {
          background-color: transparent;
          color: #6c65e4;
          font-size: 14px;
          letter-spacing: 0;
        }
      }
    }
  }
}
</style>
